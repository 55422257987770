<template>
    <el-dialog v-if="m" :title="title" :visible.sync="isShow" width="550px" top="10vh" :append-to-body="true" :destroy-on-close="true" :close-on-click-modal="false" custom-class="full-dialog">
        <div class="vue-box">
            <!-- 参数栏 -->
            <div class="c-panel">
                <el-form size="mini" v-if="m" ref="ruleForm" :rules="rules" :model="m" class="demo-ruleForm" label-width="120px">
                    <el-form-item label="任务：" prop="taskId">
                        <span>{{m.task.name}}</span>
                    </el-form-item>
                    <el-form-item label="描述：" prop="title">
                        <el-input v-model="m.title"></el-input>
                    </el-form-item>
                    <el-form-item label="防御措施：" prop="measure">
                        <el-input v-model="m.measure"></el-input>
                    </el-form-item>
                    <el-form-item label="风险等级">
                        <input-enum enumName="taskRiskLevelEnum" v-model="m.level"></input-enum>
                    </el-form-item>
                    <el-form-item label="风险状态">
                        <input-enum enumName="taskRiskStateEnum" v-model="m.state"></input-enum>
                    </el-form-item>
                    <el-form-item label="指派用户：" prop="username">
                        <el-input v-model="m.username"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <span class="c-label">&emsp;</span>
                        <el-button type="primary" icon="el-icon-plus" size="small" @click="ok('ruleForm')">确定
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import inputEnum from "../../sa-resources/com-view/input-enum.vue";
export default {
    components: { inputEnum },
    props: ["params"],
    data() {
        return {
            m: {},
            title: "",
            isShow: false,
            rules: {
                taskId: [
                    { required: true, message: "请输入任务id", trigger: "blur" }
                ],
                title: [
                    {
                        min: 3,
                        max: 200,
                        message: "长度在 3 到 200 个字符",
                        trigger: "blur"
                    }
                ],
                measure: [],
                level: [],
                state: [],
                username: []
            },
            fileList: []
        };
    },
    methods: {
        open: function(data) {
            this.isShow = true;
            if (data.id) {
                this.title = "修改开发风险";
                this.m = data;
            } else {
                this.m = {
                    taskId: data.task.id,
                    title: 0,
                    measure: "",
                    level: 0,
                    state: 0,
                    username: ""
                };
                this.m.task = data.task;
                this.title = "添加开发风险";
            }
        },

        //提交开发风险信息
        ok: function(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    this.sa.post("/taskRisk/save", this.m).then(res => {
                        console.log(res);
                        this.$parent.f5();
                        this.isShow = false;
                    });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        }
    },
    created() {}
};
</script>